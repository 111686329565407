import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { marketingApi } from 'web/api';

export const analyticsCall = async (eventName: string) => {
  const response = await marketingApi.post('analytics', {
    event_name: eventName,
    event_source_url: window.location.href,
    action_source: 'web',
  });

  return response.data;
};

export const useLogAnalyticsEvent = () => {
  return useMutation(async (data: { eventName: string }) => {
    return await analyticsCall(data.eventName);
  });
};

export const usePersistUtmParams = async () => {
  const { mutateAsync } = useLogAnalyticsEvent();
  const persisteParams = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      utm_source: urlParams.get('utm_source'),
      utm_medium: urlParams.get('utm_medium'),
      utm_campaign: urlParams.get('utm_campaign'),
      fbclid: urlParams.get('fbclid'),
    };

    if (utmParams.utm_source) {
      await marketingApi.post('persist_utm_cookie', utmParams);
      mutateAsync({ eventName: 'app_launched' });
    }
  };

  useEffect(() => {
    persisteParams();
  }, []);
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import { useEffect } from 'react';

type BackgroundAnimation = 'threads' | null;

interface SettingsSliceState {
  backgroundAnimation: BackgroundAnimation;
  feedScrollPosition: number;
}

export const initialState: SettingsSliceState = {
  backgroundAnimation: null, // allows a component to set the background animation of app layout
  feedScrollPosition: 0,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setBackgroundAnimation: (
      state: SettingsSliceState,
      action: PayloadAction<BackgroundAnimation>,
    ) => {
      state.backgroundAnimation = action.payload;
    },
    setFeedScrollPosition: (state: SettingsSliceState, action: PayloadAction<number>) => {
      state.feedScrollPosition = action.payload;
    },
  },
});

export const useSettingsState = (): SettingsSliceState =>
  useSelector((state: RootState) => state.settings);

// Action creators are generated for each case reducer function
export const { setBackgroundAnimation, setFeedScrollPosition } = settingsSlice.actions;

// Allow components to request a bg animation and ensure they unset it when they unmount or
// it stays set foreeeever
export const useBackgroundAnimation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBackgroundAnimation('threads'));

    return () => {
      dispatch(setBackgroundAnimation(null));
    };
  }, []);
};
export default settingsSlice.reducer;

import React, { useMemo } from 'react';
import cx from 'classnames';
import toast, { Toast as ReactHotToastProps } from 'react-hot-toast';
import { IoIosCloseCircle, IoMdInformationCircle, IoIosCheckmarkCircle } from 'react-icons/io';
import { IoCloseOutline } from 'react-icons/io5';

export type ToastType = 'success' | 'error' | 'info';

interface ToastProps extends ReactHotToastProps {
  dismissable?: boolean;
  toastMessage: string | React.ReactNode;
  toastType: ToastType;
  textColor?: string;
  cta?: {
    text: string;
    onClick: () => void;
  };
}

const Toast = ({
  id,
  visible,
  dismissable,
  toastMessage,
  toastType,
  textColor,
  cta,
}: ToastProps) => {
  const Icon = useMemo(() => {
    switch (toastType) {
      case 'success':
        return <IoIosCheckmarkCircle className="fill-highlight-mid" size={20} />;
      case 'error':
        return <IoIosCloseCircle className="fill-red" size={20} />;
      case 'info':
        return <IoMdInformationCircle className="fill-aqua" size={20} />;
      default:
        return null;
    }
  }, [toastType]);

  const classes = {
    toastContainer: cx(
      visible ? 'animate-toastEnter' : 'animate-toastLeave',
      'pointer-events-auto',
      'bg-scene-shade border-gray-light border rounded-lg drop-shadow-sm',
      'flex w-full max-w-md p-4 items-start',
    ),
    toastMessage: cx('text-sm', textColor || 'text-white'),
    toastCtaText: 'text-sm text-white font-bold',
    toastMessageContainer: 'ml-3 flex-1',
    toastCtaContainer: 'mt-2 text-center',
  };

  return (
    <div className={classes.toastContainer}>
      {Icon}
      <div className={classes.toastMessageContainer}>
        <p className={classes.toastMessage}>{toastMessage}</p>

        {cta && (
          <div className={classes.toastCtaContainer}>
            <button
              className={classes.toastCtaText}
              onClick={() => {
                cta.onClick();
                toast.dismiss(id);
              }}
              type="button">
              {cta.text}
            </button>
          </div>
        )}
      </div>

      {dismissable && (
        <button
          aria-label="Close"
          type="button"
          onClick={() => toast.dismiss(id)}
          className="mr-[-10px] mt-[-10px]">
          <IoCloseOutline className="fill-highlight-mid" opacity={0.7} size={20} />
        </button>
      )}
    </div>
  );
};

export default Toast;
